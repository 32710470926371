import http from '../http';

export function projectList (params) {
    return http({
        url: "/api/project/projectPage",
        method: "get",
        params:params
    })
}
export function getProjectDetail (params) {
    return http({
        url: "/api/project/project",
        method: "get",
        params:params
    })
}
export function projectReimburseFile (params) {
    return http({
        url: "/api/fileManage/projectReimburseFile",
        method: "get",
        params:params
    })
}


export function getProjectInfo (params) {
    return http({
        url: "/api/project/projectDetail",
        method: "get",
        params:params
    })
}
export function editProject (data) {
    return http({
        url: "/api/project/project",
        method: "post",
        data:data
    })
}
export function discardProject (data) {
    return http({
        url: "/api/project/project",
        method: "post",
        data:data
    })
}
export function jtSub (data) {
    return http({
        url: "/api/project/projectMc",
        method: "put",
        data:data
    })
}
export function cdSub (data) {
    return http({
        url: "/api/project/projectPreUpdate",
        method: "post",
        data:data
    })
}
export function noPass (data) {
    return http({
        url: "/api/project/projectNoPreUpdate",
        method: "post",
        data:data
    })
}
export function deletePro (data) {
    return http({
        url: "/api/project/deleteProject",
        method: "delete",
        data:data
    })
}

// 添加/修改实收金额
export function setRealPrice (data) {
    return http({
        url: "/api/project/setRealPrice",
        method: "post",
        data:data
    })
}

//获取项目参与人员（用于文件权限管理）
export function getProjectMember (params) {
    return http({
        url: "/api/project/getPlanMemberListFormat",
        method: "get",
        params:params
    })
}

//获取审批记录气泡显示
export function getSpjl (params) {
    return http({
        url: "/api/project/approvalProcess",
        method: "get",
        params:params
    })
}

//获取成单信息
export function getCdinfo (params) {
    return http({
        url: "/api/project/projectDealDetail",
        method: "get",
        params:params
    })
}
//获取市场结题
export function getMc (params) {
    return http({
        url: "/api/project/projectMc",
        method: "get",
        params:params
    })
}

//获取功能清单版本列表
export function getFunList (params) {
    return http({
        url: "/api/project/functionListPage",
        method: "get",
        params:params
    })
}

//创建功能清单
export function newFunction (data) {
    return http({
        url: "/api/project/functionListAdd",
        method: "post",
        data:data
    })
}
//修改功能清单
export function editFunction (data) {
    return http({
        url: "/api/project/functionListEdit",
        method: "post",
        data:data
    })
}
//功能清单详情
export function detailFunction (params) {
    return http({
        url: "/api/project/functionListDetail",
        method: "get",
        params:params
    })
}
//功能清单确认版本
export function confirmVersion (data) {
    return http({
        url: "/api/project/confirmVersion",
        method: "post",
        data:data
    })
}

//点击箭头时获取子项目
export function getProChildren (params) {
    return http({
        url: "/api/project/projectSon",
        method: "get",
        params:params
    })
}