<template>
  <div v-loading="loading">
    <!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"
        @department_idChange="department_idChange"
    />


    <!--统计表-->
    <div class="total_salary">
      <el-table
          :data="totalBusinessData"
          border
          style="width: 100%"
      >
        <el-table-column
            header-align="center"
            align="center"
            prop="all_num"
            label="合成成单数量"

        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="all_purchase_all_price"
            label="合计进货总价格(元)"
        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="all_shipment_all_price"
            label="合计出货总价格(元)"
        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="all_price_difference"
            label="合计总差价(元)"
        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="all_actual_logistics_expenses"
            label="合计总物流费(元)"
        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="all_cost_of_capital"
            label="合计总资金成本(元)"
        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="all_taxation"
            label="合计总税费(元)"
        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="all_after_tax_revenue"
            label="合计总税后营收(元)"
        >
        </el-table-column>
      </el-table>
    </div>

    <div class="total_salary">
      <!--table 组件 -->
      <el-table
          :data="list"
          border
          style="width: 100%"
      >

        <el-table-column
            header-align="center"
            align="center"
            prop="department_name"
            label="发起部门"

        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="user_info.name"
            label="发起人"

        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="num"
            label="成单数量"

        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="purchase_all_price"
            label="进货总价格(元)"

        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="shipment_all_price"
            label="出货总价格(元)"

        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="price_difference"
            label="总差价(元)"

        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="actual_logistics_expenses"
            label="总物流费(元)"

        >
        </el-table-column>

        <el-table-column
            header-align="center"
            align="center"
            prop="cost_of_capital"
            label="总资金成本(元)"

        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="taxation"
            label="总税费(元)"

        >
        </el-table-column>
        <el-table-column
            header-align="center"
            align="center"
            prop="after_tax_revenue"
            label="总税后营收(元)"
        >
        </el-table-column>
      </el-table>

    </div>


  </div>


</template>

<script>

import {allNormalUserName, businessStatisticsDay} from "api/business/business_func";
import {oneDepartment, twoDepartment} from "api/user/user_func";

export default {
  data() {
    // eslint-disable-next-line vue/no-dupe-keys
    return {
      //tabs 数据源
      tabsProp: {
        active: '0',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: true,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '发起部门',
          field: 'department_id',
          type: 3,
        }, {
          name: '发起人',
          field: 'user_id',
          type: 2,
        }, {
          name: '发起时间',
          field: 'start_time',
          getType:"month",
          type: 400,
        }, {
          name: '结束时间',
          field: 'end_time',
          getType:"month",
          type: 400,
        },],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        department_idOptions: [],
        user_idOptions: [],
        transaction_loserOptions: [],

        //高级搜索的字段
        heighKewWordField: {department_id: '', user_id: '', start_time: '', end_time: ''},
        //tabs 标题
        tabsData: [
          {
            label: '按月',
            page: 'month',
            name: '0',
          },
          {
            label: '按日',
            page: 'date',
            name: '1',
          },
        ]
      },
      list: [],


      pageInfo: "month",

      loading: false,
      dialogVisible: {
        active: false
      },
      timeScope: '',
      tabStatus: 0,
      userlist: [],
      tableData: [],

      // statsData
      totalBusinessData: []
    }
  },

  methods: {
    //tabs 切换点击
    tabsClick(tab) {

      this.pageInfo = this.tabsProp.tabsData[tab.name].page
      this.tabsProp.highKeyWord[2].getType = this.pageInfo
      this.tabsProp.highKeyWord[3].getType = this.pageInfo

    },


    //tabs 点击搜索
    keyWordMethod() {
      console.log(this.tabsProp.keywords);//搜索的值

      this.tableProp.page.current_page = 1;

      this.initLoad()
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod: function (val) {
      let param = JSON.parse(JSON.stringify(this.tabsProp.heighKewWordField))
      if (param.start_time) {
        param.start_time = this.formatDate(param.start_time / 1000);
      }
      if (param.end_time) {
        param.end_time = this.formatDate(param.end_time / 1000);
      }
      console.log(val)
      this.initLoad(param)
    },

    formatDate(item_date) {
      let date = new Date(parseInt(item_date) * 1000);
      let YY = date.getFullYear() + "-";
      let MM =
          (date.getMonth() + 1 < 10
              ? "0" + (date.getMonth() + 1)
              : date.getMonth() + 1) + "-";
      let DD = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
      // let hh =
      //     (date.getHours() < 10 ? "0" + date.getHours() : date.getHours()) + ":";
      // let mm =
      //     (date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes()) +
      //     ":";
      // let ss =
      //     date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
      return YY + MM + DD;
    },

    //表格初始化
    initLoad(params) {
      // this.$store.state.loading = true;
      params.type =parseInt(this.tabsProp.active)+1
      console.log(params)
      businessStatisticsDay(params).then((result) => {
        let res = result.data;
        this.totalBusinessData=[];
        let totalBusinessDataJson = {
          all_actual_logistics_expenses: res.all_actual_logistics_expenses,//''
          all_after_tax_revenue: res.all_after_tax_revenue,//'',
          all_num: res.all_num,//'',
          all_cost_of_capital: res.all_cost_of_capital,//'',
          all_price_difference: res.all_price_difference,//'',
          all_purchase_all_price: res.all_purchase_all_price,//'',
          all_shipment_all_price: res.all_shipment_all_price,//'',
          all_taxation: res.all_taxation,//'',

        }
        this.totalBusinessData.push(totalBusinessDataJson)
        this.list = res.list
        console.log(this.list)
      })
    },
    department_idChange(val) {
      let data = {
        pid: val
      }
      if (val != '') {
        twoDepartment(data).then(res => {
          let list = res.data;
          for (let i = 0; i < list.length; i++) {
            list[i].label = list[i].department_name;
            list[i].value = list[i].id;
          }
          this.tabsProp.station_idOptions = list
        })
      }
    },
    getDepartmentList(){
      //获取部门信息
      oneDepartment({}).then(res=>{
        let list = res.data;
        for (let i = 0; i < list.length; i++) {
          list[i].label = list[i].department_name;
          list[i].value = list[i].id ;
        }
        this.tabsProp.department_idOptions = list
      })
    },

  },
  created() {
    this.getDepartmentList()

    let params = {}

    allNormalUserName({}).then((result) => {
      console.log(result)
      let data = result.data;
      for (let i = 0; i < data.length; i++) {
        data[i].value = data[i].id
        data[i].label = data[i].name
      }
      this.tabsProp.user_idOptions = data

    })
    this.initLoad(params);

  },
  mounted() {
    this.$on('open', function () {
      this.dialogVisible = true
    });
  }

}
</script>
<style lang="scss" scoped>
.content-tile {
  @include flex(nowrap, space-between, center);
  background: $title-bg;
  border: 1px solid $title-bg;
  padding: 8px;
  margin-bottom: 12px;

  .su-title {
    font-weight: bold;
    @include flex();

    i {
      font-size: 16px;
      color: $title-icon;
      font-weight: bold;
      margin-right: 8px;
    }
  }

  .content {
    padding: 40px;
  }
}

.paginationBox {
  padding-top: 20px;
  text-align: right;
}

.total_salary {
  margin-bottom: 15px;
}

</style>
