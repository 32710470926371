<template>
  <div>
    <!-- title 组件 -->
    <common-title
        :titleProp="titleProp"

    /><!--tabs 组件 -->
    <common-tabs
        :tabsProp="tabsProp"
        @tabsClick="tabsClick"
        @keyWordMethod="keyWordMethod"
        @highKeyWordMethod="highKeyWordMethod"

    />
    <!--table 组件 -->
    <common-table
        :tableData="tableData"
        :tableProp="tableProp"
        :spjl="spjl"
        @pageCurrentChange="pageCurrentChange"
        @handleSizeChange="handleSizeChange"
        @detileProject="detileProject"
        @passOrder="passOrder"
        @endProject="endProject"
        @editProject="editProject"
        @noPassOrder="noPassOrder"
        @discardProject="discardProject"
        @endProjectJs="endProjectJs"
        @finish="finish"
        @finishPro="finishPro"
        @getProject="getProject"
        @reProject="reProject"
        @load="load"
    />

    <!--成单模态框 组件 -->
    <el-dialog
        :append-to-body="true"
        :title="cdData.title"
        :visible.sync="cdData.isDialog"
        :top="cdData.top"
        :width="cdData.width"
    >
      <div class="common-add-dialog">
        <ul class="cdul">
          <li>
            <span :style="{'width':cdData.labelWidth}">项目集</span>
            <div>{{cdData.addDialogShowData.items_title}}</div>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}">项目名称</span>
            <div>{{cdData.addDialogShowData.project_name}}</div>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}">项目编号</span>
            <div>{{cdData.addDialogShowData.project_sn}}</div>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}">项目实际编号</span>
            <el-input v-model="cdData.addDialogShowData.deal_sn" placeholder="请输入项目实际编号"></el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">保密等级</span>
            <el-select v-model="cdData.addDialogShowData.security_level" placeholder="请选择保密等级" @change="setText" style="width: 39%">
              <el-option label="一级" value="1"></el-option>
              <el-option label="二级" value="2"></el-option>
              <el-option label="三级" value="3"></el-option>
            </el-select>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}"></span>
            <div>{{cdData.levelText}}</div>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">项目成单金额(万元)</span>
            <el-input v-model="cdData.addDialogShowData.deal_price"></el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">成单时间</span>
            <el-date-picker type="date" placeholder="请选择日期" v-model="cdData.addDialogShowData.start_time" value-format="timestamp"
                            @change="getCycleTime('start_time')"
                            style="width: 100%;"></el-date-picker>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">计划交付时间</span>
            <el-date-picker type="date" placeholder="请选择日期" v-model="cdData.addDialogShowData.end_time" value-format="timestamp"
                            @change="getCycleTime('end_time')"
                            style="width: 100%;"></el-date-picker>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">计划项目周期(天)</span>
            <el-input v-model="cdData.addDialogShowData.cycle_time" @change="setEndTime()"></el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">居间成本</span>
            <el-input v-model="cdData.addDialogShowData.source_cost">
              <el-select v-model="cdData.addDialogShowData.source_cost_is_rate" slot="prepend" placeholder="请选择" style="width: 80px">
                <el-option label="比例" value="1"></el-option>
                <el-option label="金额" value="0"></el-option>
              </el-select>
              <template slot="append" v-if="cdData.addDialogShowData.source_cost_is_rate == 1">%</template>
              <template slot="append" v-else>万元</template>
            </el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}">项目联系人</span>
            <el-input v-model="cdData.addDialogShowData.customer"></el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}">联系方式</span>
            <el-input v-model="cdData.addDialogShowData.customer_tel"></el-input>
          </li>
<!--          里程碑-->
          <div style="border-bottom: 1px solid; margin: 10px 20px; padding: 10px 0">里程碑信息</div>
          <li>
            <el-button type="primary" @click="addMilepost" style="margin: 0 20px">添加里程碑</el-button>
          </li>
          <common-table
              :tableData="cdData.addDialogShowData.milepost"
              :tableProp="milepostTableProp"
              @deleteMilepost = "deleteMilepost"
              style="margin: 0px 20px"
          />
<!--          收款计划-->
          <div style="border-bottom: 1px solid; margin: 10px 20px; padding: 10px 0">收款计划</div>
          <li>
            <el-button type="primary" @click="addPlan" style="margin: 0 20px">添加期数</el-button>
          </li>
          <common-table
              :tableData="cdData.addDialogShowData.receive_money_plan"
              :tableProp="paymentPlanProp"
              @deletePlan = "deletePlan"
              style="margin: 0px 20px"
          />
          <!--上传文件-->
          <div style="border-bottom: 1px solid; margin: 10px 20px; padding: 10px 0">合同附件信息</div>
          <li style="align-items: baseline">
            <span :style="{'width':cdData.labelWidth}">成单附件</span>
            <template>
              <common-new-file :uploadFileData="cdData.addDialogShowData.deal_attachment_json" :member = 'member'/>
            </template>
          </li>
<!--          <li>
            <span :style="{'width':cdData.labelWidth}">功能清单</span>
            &lt;!&ndash;功能清单&ndash;&gt;
            <el-button @click="addFirstGn">添加一级功能模块</el-button>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}"></span>
&lt;!&ndash;            功能清单&ndash;&gt;
            <div class="custom-tree-container">
              <el-tree
                  :data="cdData.addDialogShowData.function_json"
                  node-key="id"
                  default-expand-all
                  :expand-on-click-node="false"
                  :render-content="renderContent">
              </el-tree>
            </div>

          </li>-->
          <common-shenp :shenP="cdshenP"></common-shenp>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="cdData.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="subCd">确 定</el-button>
      </div>
    </el-dialog>
<!--    功能名称 组件 -->
<!--    <el-dialog
        :append-to-body="true"
        title="功能名称"
        :visible.sync="gnData.isDialog"
        :top="cdData.top"
        :width="cdData.width"
    >
      <div class="common-add-dialog">
        <el-button @click="addMore" style="margin-bottom: 15px; margin-left: 10px">+新增</el-button>
        <div v-for="(item, index) in newData" :key="item.id">
          <el-input  v-model="item.label" style="padding: 5px 10px; width: 90%"></el-input>
          <el-button type="danger" @click="deleteMore(index)">删除</el-button>
        </div>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="gnData.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="append">确 定</el-button>
      </div>
    </el-dialog>-->
    <!--市场结题 组件 -->
    <el-dialog
        :append-to-body="true"
        title="项目结题"
        :visible.sync="jtData.isDialog"
        :top="cdData.top"
        width= "1055px"
    >
      <div class="common-add-dialog">
        <ul class="cdul">
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">商务经费总结</span>
            <el-input type="textarea" v-model="jtData.data.fund_summary"></el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">项目周期总结</span>
            <el-input type="textarea" v-model="jtData.data.cycle_summary"></el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">客户稳定度</span>
            <el-input type="textarea" v-model="jtData.data.customer_stability"></el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">二期诉求</span>
            <el-input type="textarea" v-model="jtData.data.second_ask"></el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">产品市场价值</span>
            <el-input type="textarea" v-model="jtData.data.market_value"></el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">居间成本</span>
            <el-input v-model="jtData.data.real_market_cost">
              <el-select v-model="jtData.data.source_cost_is_rate" slot="prepend" placeholder="请选择" style="width: 80px">
                <el-option label="比例" value="1"></el-option>
                <el-option label="金额" value="0"></el-option>
              </el-select>
              <template slot="append" v-if="jtData.data.source_cost_is_rate == 1">%</template>
              <template slot="append" v-else>万元</template>
            </el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}" class="required2">实际外部协作成本</span>
            <el-input v-model="jtData.data.real_external_cost">
              <el-select v-model="jtData.data.external_cost_is_rate" slot="prepend" placeholder="请选择" style="width: 80px">
                <el-option label="比例" value="1"></el-option>
                <el-option label="金额" value="0"></el-option>
              </el-select>
              <template slot="append" v-if="jtData.data.external_cost_is_rate == 1">%</template>
              <template slot="append" v-else>万元</template>
            </el-input>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}">实际参与人员及分红申请</span>
            <el-button type="primary" @click="addMember">添加成员</el-button>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}"></span>
            <common-table
                :tableData="jtData.data.member_json"
                :tableProp="ryTableProp"
                @department_nameDialogChange = "getPost"
                @post_nameDialogChange = "getUser"
                @user_idDialogChange = "getUserName"
                @deleteMember = "deleteMember"
                @red_rateChange = 'red_rateChange'
                @grow_rateChange = 'grow_rateChange'
            />
          </li>
          <li>添加市场结题附件（市场结题文件、分红申请表）</li>
          <li>
            <span :style="{'width':cdData.labelWidth}"></span>
            <template>
              <common-new-file :uploadFileData="jtData.data.attachment_json" :member="member"/>
            </template>
          </li>
          <li>
            <span :style="{'width':cdData.labelWidth}"></span>
          </li>
          <common-shenp :shenP="jtshenP"></common-shenp>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="jtData.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="subJt">确 定</el-button>
      </div>
    </el-dialog>
    <!--技术结题 组件 -->
    <el-dialog
        :append-to-body="true"
        title="项目结题"
        :visible.sync="jtDatajs.isDialog"
        top="50px"
        width= "1055px"
    >
      <div class="common-add-dialog">
        <ul class="cdul">
          <li>
            <span :style="{'width':jtDatajs.labelWidth}" class="required2">情况说明</span>
            <el-input type="textarea" v-model="jtDatajs.data.explain"></el-input>
          </li>
          <li>
            <span :style="{'width':jtDatajs.labelWidth}">项目周期总结</span>
            <common-table
                :tableData="zqzjData"
                :tableProp="zqzjTableProp"
            />
          </li>
          <li>
            <span :style="{'width':jtDatajs.labelWidth}" class="required2">问题与经验总结</span>
            <el-input type="textarea" v-model="jtDatajs.data.problem_summary"></el-input>
          </li>
          <li>
            <span :style="{'width':jtDatajs.labelWidth}" class="required2">团队成长说明</span>
            <el-input type="textarea" v-model="jtDatajs.data.grow_summary"></el-input>
          </li>
          <li>
            <span :style="{'width':jtDatajs.labelWidth}" class="required2">模块贡献说明</span>
            <el-input type="textarea" v-model="jtDatajs.data.contribution_summary"></el-input>
          </li>
          <li>
            <span :style="{'width':jtDatajs.labelWidth}">实际参与人员及分红申请</span>
            <el-button type="primary" @click="addMemberjs">添加成员</el-button>
          </li>
          <li>
            <span :style="{'width':jtDatajs.labelWidth}"></span>
            <common-table
                :tableData="jtDatajs.data.member_json"
                :tableProp="ryTablePropjs"
                @department_nameDialogChange = "getPost"
                @post_nameDialogChange = "getUser"
                @user_idDialogChange = "getUserName"
                @deleteMember = "deleteMemberjs"
                @red_rateChange = 'red_rateChange'
                @grow_rateChange = 'grow_rateChange'/>
          </li>
          <li>添加市场结题附件（技术结题文件、分红申请表）</li>
          <li>
            <span :style="{'width':jtDatajs.labelWidth}"></span>
            <template>
              <common-new-file :uploadFileData="jtDatajs.data.attachment_json" :member="member"/>
            </template>
          </li>
          <common-shenp :shenP="shenPjs"></common-shenp>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="jtDatajs.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="subJtjs">确 定</el-button>
      </div>
    </el-dialog>
    <!--揭榜弹窗-->
    <el-dialog
        :append-to-body="true"
        :title="jbData.title"
        :visible.sync="jbData.isDialog"
        :top="jbData.top"
        :width="jbData.width"
    >
      <div class="common-add-dialog">
        <ul>
          <li>
            <span :style="{'width':jbData.labelWidth}">揭榜人</span>
            <el-select v-model="jbData.addDialogShowData.user_id" :disabled="depName== '总经办'?false:true">
              <el-option
                  v-for="(itemOption,keyOption) in userName"
                  :key="keyOption"
                  :label="itemOption.name"
                  :value="itemOption.id">
              </el-option>
            </el-select>
            <span :style="{'width':jbData.labelWidth}" class="required2">期望分红比例(%)</span>
            <el-input v-model="jbData.addDialogShowData.dev_hope_red_rate" placeholder="请输入期望分红比例" style="width: 36.7%"></el-input>
          </li>
          <li>
            <span :style="{'width':jbData.labelWidth}" class="required2">期望成长值</span>
            <el-input v-model="jbData.addDialogShowData.dev_hope_grow_rate" placeholder="请输入期望成长值" style="width: 36.2%;"></el-input>
          </li>
          <li>
            <span :style="{'width':jbData.labelWidth}" class="required2">部署要求</span>
            <el-input v-model="jbData.addDialogShowData.deployment_req" placeholder="请输入部署要求"></el-input>
          </li>
          <li>
            <span :style="{'width':jbData.labelWidth}" class="required2">任务要求</span>
            <el-input v-model="jbData.addDialogShowData.mission_req" placeholder="请输入任务要求"></el-input>
          </li>
          <li>
            <span :style="{'width':jbData.labelWidth}" class="required2">周期控制奖惩</span>
            <el-input v-model="jbData.addDialogShowData.cycle_rp" type="textarea" placeholder="请输入周期控制奖惩"></el-input>
          </li>
          <li>
            <span :style="{'width':jbData.labelWidth}" class="required2">任务完成质量奖罚</span>
            <el-input v-model="jbData.addDialogShowData.task_completion_rp" type="textarea" placeholder="请输入任务完成质量奖罚"></el-input>
          </li>
          <li>
            <span :style="{'width':jbData.labelWidth}" class="required2">其他奖惩</span>
            <el-input v-model="jbData.addDialogShowData.other_reward" type="textarea" placeholder="请输入其他奖惩"></el-input>
          </li>
          <li>
            <span :style="{'width':jbData.labelWidth}">计划明细表</span>
            <el-button type="primary" @click="addMemberjb">添加成员</el-button>
          </li>
          <li>
            <span :style="{'width':jbData.labelWidth}"></span>
            <common-table
                :tableData="jbData.addDialogShowData.member_json"
                :tableProp="jhmxtableProp"
                @department_nameDialogChange = "getPost"
                @post_nameDialogChange = "getUser"
                @user_idDialogChange = "getUserName"
                @deleteMemberjb = "deleteMemberjb"
                @start_timeChange = "start_timeChange"
                @end_timeChange = "end_timeChange"
                @join_rateChange = "join_rateChange"/>
          </li>
          <li>
            <span :style="{'width':jbData.labelWidth}">开发计划表</span>
            <common-new-file :uploadFileData="jbData.addDialogShowData.attachment_json" :member = 'member'/>
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button @click="jbData.isDialog=false">取 消</el-button>
        <el-button type="primary" @click="jbSub()">确 定</el-button>
      </div>
    </el-dialog>
    <!--申请报销-->
    <el-dialog
        title="申请报销"
        :visible.sync="dialogVisible"
        width="20%"
        :append-to-body="true">
      <el-form ref="form"  label-width="80px">
        <el-form-item label="报销类型">
          <el-select v-model="reimbursementType" placeholder="请选择报销类型">
            <el-option label="差旅费用报销" value="2"></el-option>
            <el-option label="普通费用报销" value="1"></el-option>

          </el-select>
        </el-form-item>
      </el-form>


      <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="jumpToCreateReimbursement">确 定</el-button>
        </span>
    </el-dialog>

  </div>
</template>
<script>
import {
  cdSub,
  deletePro, getCdinfo, getMc, getProChildren,
  getProjectMember,
  jtSub,
  noPass,
  projectList
} from "@/api/project_management/project_list";
import {getDepartment, getDepUser, getPost, getUser} from "@/api/project_management/new_project";
import {getProjectType} from "../../../api/project_management/new_project";
import {
  getTaskDelayList,
  getJtDetail,
  jtSubjs,
  endProject,
  projectFinish,
} from '@/api/project_management/project_execution_summary'
import {editProjectItem, jbDetail, projectItem} from "api/project/project_func";
import {getshenPr} from "@/api/basic_management/template_management";
import {timer} from "assets/js/comment";

export default {
  components: {},
  props: [],
  data() {
    return {
      start_time: '',
      end_time: '',
      jbtype: 0,
      spjl: [],
      depName:'',
      userName: [],
      //报销类型
      bxId: '',
      reimbursementType: '1',
      dialogVisible: false,
      //是否显示全部
      type: 1,
      treeId: 1000,
      //title 数据源
      titleProp: {
        name: '项目列表',//标题名称
        butOperate: []
      },
      //tabs 数据源
      tabsProp: {
        active: '',
        method: 'tabsClick', //点击tabs方法名
        keywords: '',//搜索的值
        keyWordMethod: 'keyWordMethod',// 搜索方法名
        keywordsShow: false,//搜索框是否显示
        isHighKeyWord: true,//是否有高级搜索
        highKeyWordDialogMethod: 'highKeyWordMethod',//高级搜索点击确定*/
        //高级搜索循环体
        highKeyWord: [{
          name: '项目名称',
          field: 'projectName',
          type: 1,
        },  {
          name: '项目类型',
          field: 'typeId',
          type: 3,
        },{
          name: '状态',
          field: 'tab_type',
          type: 3,
        },{
          name: '商务负责人',
          field: 'busUserId',
          type: 3,
        },{
          name: '技术负责人',
          field: 'devUserId',
          type: 3,
        }],
        //高级循环，下拉框等 内容名称 字段名+Options  接口读取
        typeIdOptions: [],
        busUserIdOptions: [],
        devUserIdOptions: [],
        tab_typeOptions: [
          {value: '2', label: '立项待审批'},
          {value: '3', label: '待成单'},
          {value: '4', label: '待揭榜'},
          {value: '5', label: '待完工'},
          {value: '6', label: '待验收'},
          {value: '7', label: '待结题-技术'},
          {value: '8', label: '待结题-市场'},
          {value: '9', label: '待结算'},
          {value: '10', label: '已完成'},
        ],

        //高级搜索的字段
        heighKewWordField: {status: '', projectName: '', typeId: ''},
        //tabs 标题
        //默认全部，1=不成单，2=立项待审批， 3=待成单，4=待揭榜，5=待完工，6=待验收，7=待结题-技术，8=待结题-市场,9=待结算，10=完成
        tabsData: [{
          label: '立项项目',
          name: '',
          total: 0,
        },{
          label: '不成单',
          name: '1',
          total: 0,
        },{
          label: '立项待审批',
          name: '2',
          total: 0,
        },{
          label: '待成单',
          name: '3',
          total: 0,
        },{
          label: '待揭榜',
          name: '4',
          total: 0,
        },{
          label: '待完工',
          name: '5',
          total: 0,
        },{
          label: '待验收',
          name: '6',
          total: 0,
        },{
          label: '待结题-技术',
          name: '7',
          total: 0,
        },{
          label: '待结题-市场',
          name: '8',
          total: 0,
        },{
          label: '待结算',
          name: '9',
          total: 0,
        },{
          label: '已完成',
          name: '10',
          total: 0,
        }]
      },
      //table 数据源
      tableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: true, //是否分页
        statusName: 'statusName',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        load:'load', //懒加载方法名
        page: {
          type: 2, 
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
          method2: 'handleSizeChange',
        },
        table: [{
          title: '项目编号',
          field: 'project_sn',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '项目名称',
          field: 'project_title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },
        //   {
        //   title: '项目集',
        //   field: 'items_title',
        //   isWidth: false,
        //   width: '120px',
        //   headerAlign: 'center',
        //   align: 'center',
        //   tooltip: false,
        //   type: 0,
        // },
          {
          title: '项目类型',
          field: 'project_type',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'type_name'
        }, {
          title: '商务负责人',
          field: 'xmjl',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '技术负责人',
          field: 'dev_user',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name',
        }, {
          title: '项目金额(万元)',
          field: 'all_price',
          isWidth: false,
          width: '110px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        },
          {
          title: '市场分红（元）',
          field: 'market_red_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '技术分红（元）',
          field: 'dev_red_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '报销金额（元）',
          field: 'reimburse_all_price',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '计划成单时间',
          field: 'start_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '计划交付时间',
          field: 'end_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 4,
        }, {
          title: '项目状态',
          field: 'status',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '审批状态',
          field: 'audit_status',
          isWidth: false,
          width: '100px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 12,
          infolist: 'audit_status_info',
        }, {
          title: '操作',
          isWidth: false,
          width: '245px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '详情',
            type: 'primary',
            method: 'detileProject',
            status: '20000',
            id: 20000,
          }, {
            name: '报销申请',
            type: 'primary',
            method: 'reProject',
            status: 'control',
            // status: '20000',
            id: 20000,
          }, {
            name: '成单',
            type: 'primary',
            method: 'passOrder',
            status: '0',
            // status: '20000',
            id: 20000,
          }, {
            name: '不成单',
            type: 'primary',
            method: 'noPassOrder',
            status: '0',
            id: 20000,
          }, {
            name: '编辑',
            type: 'primary',
            method: 'editProject',
            status: '0',
            id: 20000,
          }, {
            name: '申请揭榜',
            type: 'primary',
            method: 'getProject',
            status: '0',
            id: 20000,
          }, {
            name: '市场结题',
            type: 'primary',
            method: 'endProject',
            status: '0',
            id: 20000,
          }, {
            name: '技术结题',
            type: 'primary',
            method: 'endProjectJs',
            status: '0',
            id: 20000,
          }, {
            name: '完工',
            type: 'primary',
            method: 'finish',
            status: '0',
            id: 20000,
          }, {
            name: '删除',
            type: 'danger',
            method: 'discardProject',
            status: '0',
            id: 20000,
          }, {
            name: '项目完结',
            type: 'primary',
            method: 'finishPro',
            status: '0',
            id: 20000,
          }],
        }],
      },
      //参与人员table 数据源
      ryTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 8,
          labelClassName: 'required3'
        }, {
          title: '岗位',
          field: 'post_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
          labelClassName: 'required3'
        }, {
          title: '姓名',
          field: 'user_id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
          labelClassName: 'required3'
        }, {
          title: '分红比例(%)',
          field: 'red_rate',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '成长值比例(%)',
          field: 'grow_rate',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deleteMember',
            status: '20000',
            id: 20000,
          }],

        }],
        department_nameProp: [],
      },
      ryTablePropjs: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 8,
          labelClassName: 'required3'
        }, {
          title: '岗位',
          field: 'post_name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
          labelClassName: 'required3'
        }, {
          title: '姓名',
          field: 'user_id',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
          labelClassName: 'required3'
        }, {
          title: '分红比例(%)',
          field: 'red_rate',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '成长值比例(%)',
          field: 'grow_rate',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deleteMember',
            status: '20000',
            id: 20000,
          }],

        }],
        department_nameProp: [],
      },
      //周期总结 table 数据源
      zqzjTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '姓名',
          field: 'user',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'name',
        }, {
          title: '岗位',
          field: 'user_station',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 3,
          name: 'station_name',
        }, {
          title: '任务',
          field: 'name',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }, {
          title: '延期/提前天数',
          field: 'delay_day',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 0,
        }],
      },
      //揭榜
      jbData: {
        isDialog: false,//dialog是否显示
        title: '揭榜',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '1456px', // dialog 长度，可以是百分比
        labelWidth: '160px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          user_id: '',
          project_id: '',
          dev_hope_red_rate: '',
          dev_hope_grow_rate: '',
          deployment_req: '',
          mission_req: '',
          cycle_rp: '',
          task_completion_rp: '',
          other_reward: '',
          member_json: [{
            user_id: '',
            department_name: '',
            post_name: '',
            start_time: "",
            end_time: "",
            join_rate: '',
            post_nameProp: [],
            user_idProp: [],
            achievements:'',
          }],
          attachment_json:[],
        },
        department_nameProp:[{value: 1, label: '我是选项1'},
          {value: 2, label: '我是选项2'}]
      },
      jhmxtableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '部门',
          field: 'department_name',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 8,
          labelClassName: 'required3'
        }, {
          title: '岗位',
          field: 'post_name',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
          labelClassName: 'required3'
        }, {
          title: '姓名',
          field: 'user_id',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 6,
          labelClassName: 'required3'
        }, {
          title: '任务名称',
          field: 'task_name',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
        }, {
          title: '计划开始时间',
          field: 'start_time',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
        }, {
          title: '计划结束时间',
          field: 'end_time',
          isWidth: false,
          width: '150px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
        }, {
          title: '计划参与度(%)',
          field: 'join_rate',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '绩效',
          field: 'achievements',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deleteMemberjb',
            status: '20000',
            id: 20000,
          }],

        }],
        department_nameProp: [],
      },
      //成单
      cdData: {
        levelText: '',
        isDialog: false,//dialog是否显示
        title: '成单',// dialog 标题
        top: '50px', // dialog 离浏览器top
        width: '900px', // dialog 长度，可以是百分比
        labelWidth: '155px', // label 长度
        //dialog 显示数据
        addDialogShowData: {
          id:'',
          items_title:'',
          project_name:'',
          project_sn:'',
          deal_sn:'',
          security_level:'',
          deal_price:'',
          start_time:'',
          end_time:'',
          cycle_time:'',
          source_cost_is_rate: '1',
          source_cost:'',
          customer:'',
          customer_tel:'',
          deal_attachment_json: [],
          receive_money_plan:[],
          milepost:[],
        },
      },
      gnData: {
        isDialog: false,
        name:'',
        clickData: {},
        type: '',
      },
      newData: [{
        id: Math.round(Math.random()*123456+0),
        label: '',
        children: []
      }],
      //成单里程碑
      milepostTableProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '里程碑名称',
          field: 'title',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '完成时间',
          field: 'finish_time',
          isWidth: false,
          width: '140px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
          labelClassName: 'required3'
        }, {
          title: '资源需求',
          field: 'source_required',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deleteMilepost',
            status: '20000',
            id: 20000,
          }],
        }],
      },
      //成单收款计划
      paymentPlanProp: {
        index: false,//是否需要有序号
        selection: false,//是否是多选框
        selectionVal: 'id',// 开启多选后对比的字段
        selectionId: [],// 开启多选后，选择哪几条
        isPage: false, //是否分页
        statusName: 'status',//状态字段名 已启动未启动等状态按钮显示还是隐藏
        page: {
          current_page: 1,
          total: 0,
          size: 10,
          method: 'pageCurrentChange',
        },
        table: [{
          title: '收款期数',
          isWidth: false,
          width: '40px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 13,
        }, {
          title: '金额（万元）',
          field: 'expect_amount',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
          labelClassName: 'required3'
        }, {
          title: '付款条件',
          field: 'condition',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 8,
          labelClassName: 'required3'
        }, {
          title: '付款备注',
          field: 'condition_other',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 5,
        }, {
          title: '预计付款时间',
          field: 'expect_receive_time',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 7,
          labelClassName: 'required3'
        }, {
          title: '操作',
          isWidth: false,
          width: '120px',
          headerAlign: 'center',
          align: 'center',
          tooltip: false,
          type: 2,
          tableOperate: [{
            name: '删除',
            type: 'danger',
            method: 'deletePlan',
            status: '20000',
            id: 20000,
          }],
        }],
        conditionProp: [
          {label:'合同签署', value:1},
          {label:'原型交付确认', value:2},
          {label:'交付完成', value:3},
          {label:'试运行完成', value:4},
          {label:'质保期完成', value:5},
          {label:'其他', value:6},
        ]
      },
      //市场结题
      jtData: {
        isDialog: false,
        name:'',
        clickData: {},
        type: '',
        data: {
          project_id: '',
          fund_summary: '',
          cycle_summary: '',
          customer_stability: '',
          second_ask: '',
          market_value: '',
          source_cost_is_rate:'1',
          external_cost_is_rate:'1',
          real_market_cost: '',
          real_external_cost: '',
          attachment_json: [],
          member_json: [{user_id:'', department_name: '', post_name: '', red_rate:'', grow_rate:'' , post_nameProp: [], user_idProp: [],}],
        }
      },
      //技术结题
      jtDatajs: {
        labelWidth: '160px',
        isDialog: false,
        name:'',
        clickData: {},
        type: '',
        data: {
          id: '',
          project_id: '',
          explain: '',
          problem_summary: '',
          grow_summary: '',
          contribution_summary: '',
          attachment_json: [],
          member_json: [{user_id:'', department_name: '', post_name: '', red_rate:'', grow_rate:'', post_nameProp: [], user_idProp: [],}],
          audit_json: [],
        }
      },
      //表格数据
      tableData: [],
      zqzjData: [],
      //addDialog提交数据
      subData: {},
      cdshenP:{
        labelWidth: "130px",
        shenPr:[],
        type: 1,
        shenpm: '',
        data:[],
      },
      jtshenP:{
        labelWidth: "155px",
        shenPr:[],
        type: 1,
        shenpm: '',
        data:[],
      },
      shenPjs:{
        labelWidth: "160px",
        shenPr:[],
        type: 1,
        shenpm: '',
        data:[],
      },
      member:[]

    };
  },
  created() {
    this.tabsProp.active = this.$route.query.active?this.$route.query.active:''
    this.initLoad();
    this.getProjectType();
    this.getProjectSet();
    this.getAllUser();
    this.getDepUser();
    this.depName = localStorage.getItem('departmentName')
  },
  mounted() {
  },
  methods: {
    getProjectType(){
      getProjectType().then(res=>{
        this.tabsProp.typeIdOptions=res.data.map(item => ({
          label: item.type_name,
          value: (item.id).toString()
        }))
      })
    },
    getAllUser(){
      getshenPr().then(res=>{
        this.tabsProp.busUserIdOptions=res.data.map(item => ({
          label: item.name,
          value:(item.id).toString()
        }))
      })
    },
    getDepUser(){
      getDepUser({department_name: '技术部'}).then(res=>{
        this.tabsProp.devUserIdOptions=res.data.map(item => ({
          label: item.name,
          value:(item.id).toString()
        }))
      })
    },
    //表格初始化
    initLoad() {
      this.$store.state.loading = true;
      let params = {
        is_show_all: this.type,
        page: this.tableProp.page.current_page,
        tab_type: this.tabsProp.active,
        per_page: this.tableProp.page.size
      }
      if (this.tabsProp.active == '' || this.tabsProp.active == 1){
        params.is_show_all = 1
      }

      if(this.tabsProp.heighKewWordField.projectName!=""){
        params.projectName = this.tabsProp.heighKewWordField.projectName
      }
      if(this.tabsProp.heighKewWordField.items_id){
        params.items_id = this.tabsProp.heighKewWordField.items_id
      }
      if(this.tabsProp.heighKewWordField.tab_type){
        params.tab_type = this.tabsProp.heighKewWordField.tab_type
      }
      if(this.tabsProp.heighKewWordField.typeId!=""){
        params.typeId = this.tabsProp.heighKewWordField.typeId
      }
      if(this.tabsProp.heighKewWordField.busUserId!=""){
        params.busUserId = this.tabsProp.heighKewWordField.busUserId
      }
      if(this.tabsProp.heighKewWordField.devUserId!=""){
        params.devUserId = this.tabsProp.heighKewWordField.devUserId
      }

      projectList(params).then((result) => {
        let res = result.data;
        this.tableProp.page.current_page = res.current_page;
        this.tableProp.page.total = res.total;
        this.tableProp.page.size = res.per_page;

        for (let i = 0; i < res.data.length; i++){
          //状态:-1=作废，0=待成单，1=成单，2=不成单，3=已指派（进行中，待完工），4=完工（待验收），5=验收（已完成）  ，6=技术结题，7=市场结题，8=项目完结
          switch (res.data[i].status) {
            case 0:
              res.data[i].status = '待成单';
              break;
            case -1:
              res.data[i].status = '作废';
              break;
            case 1:
              res.data[i].status = '成单';
              break;
            case 2:
              res.data[i].status = '不成单';
              break;
            case 3:
              res.data[i].status = '已指派';
              break;
            case 4:
              res.data[i].status = '完工';
              break;
            case 5:
              res.data[i].status = '验收';
              break;
            case 6:
              res.data[i].status = '技术结题';
              break;
            case 7:
              res.data[i].status = '市场结题';
              break;
            case 8:
              res.data[i].status = '项目完结';
              break;
          }
          //审批状态：0=待审批，1=审核通过，2=审核不通过
          switch (res.data[i].audit_status) {
            case 0:
              res.data[i].audit_status = '待审批';
              break;
            case 1:
              res.data[i].audit_status = '审核通过';
              break;
            case 2:
              res.data[i].audit_status = '审核不通过';
              break;
          }
          //报销申请按钮显隐
          switch (res.data[i].can_re) {
            case 20000:
              res.data[i].reProjectControl = 'control';
              break;
            case 0:
              res.data[i].reProjectControl = '0';
              break;
          }
          //修改按钮显隐
          switch (res.data[i].edit_btn) {
            case 1:
              res.data[i].editProjectControl = 'control';
              break;
            case 0:
              res.data[i].editProjectControl = '0';
              break;
          }
          //项目完结按钮显隐
          switch (res.data[i].project_finish_btn) {
            case 1:
              res.data[i].finishProControl = 'control';
              break;
            case 0:
              res.data[i].finishProControl = '0';
              break;
          }

          res.data[i].statusName = res.data[i].status + res.data[i].audit_status

          if (res.data[i].audit_status_info){
            for (let a = 0; a < res.data[i].audit_status_info.length; a++){
              switch (res.data[i].audit_status_info[a].status) {
                case 0:
                  res.data[i].audit_status_info[a].status = '待审批';
                  break;
                case 1:
                  res.data[i].audit_status_info[a].status = '审核通过';
                  break;
                case 2:
                  res.data[i].audit_status_info[a].status = '审核不通过';
                  break;
                case 3:
                  res.data[i].audit_status_info[a].status = '重新审批';
                  break;
              }
              switch (res.data[i].audit_status_info[a].check) {
                case 0:
                  res.data[i].audit_status_info[a].status = '查看';
                  break;
              }
            }
          } else {
            res.data[i].audit_status_info = [{name: '无'}]
          }

          if (res.data[i].children_project_count !== 0){
            res.data[i].hasChildren = true
          }
        }
        this.tableData = res.data
      })
    },
    //tabs 切换点击
    tabsClick(tab) {
      // this.tabsProp.active //tabs 当前的值
      console.log(tab.name)
      this.tableProp.page.current_page = 1;
      if (tab.name == ''){
        this.tableProp.table[13].tableOperate[1].status = 'control'    //报销申请
        this.tableProp.table[13].tableOperate[2].status = '0'  //成单
        this.tableProp.table[13].tableOperate[3].status = '0'  //不成单
        this.tableProp.table[13].tableOperate[4].status = '0'          //修改
        this.tableProp.table[13].tableOperate[5].status = '0'    //申请揭榜
        this.tableProp.table[13].tableOperate[6].status = '0'    //市场结题
        this.tableProp.table[13].tableOperate[7].status = '0'   //技术结题
        this.tableProp.table[13].tableOperate[8].status = '0'  //完工
        this.tableProp.table[13].tableOperate[9].status = '0'      //作废
        this.tableProp.table[13].tableOperate[10].status = '0'      //作废
      } else if(tab.name == 1){
        this.tableProp.table[13].tableOperate[1].status = '0'      //报销申请
        this.tableProp.table[13].tableOperate[2].status = '20000'  //成单
        this.tableProp.table[13].tableOperate[3].status = '0'     //不成单
        this.tableProp.table[13].tableOperate[4].status = '0'          //修改
        this.tableProp.table[13].tableOperate[5].status = '0'    //申请揭榜
        this.tableProp.table[13].tableOperate[6].status = '0'    //市场结题
        this.tableProp.table[13].tableOperate[7].status = '0'   //技术结题
        this.tableProp.table[13].tableOperate[8].status = '0'   //完工
        this.tableProp.table[13].tableOperate[9].status = '0'      //作废
        this.tableProp.table[13].tableOperate[10].status = '0'      //作废
      } else {
        this.tableProp.table[13].tableOperate[1].status = '0'    //报销申请
        this.tableProp.table[13].tableOperate[2].status = '待成单审核通过,成单审核不通过'    //成单
        this.tableProp.table[13].tableOperate[3].status = '待成单审核通过,成单审核不通过'    //不成单
        this.tableProp.table[13].tableOperate[4].status = 'control'    //修改
        this.tableProp.table[13].tableOperate[5].status = '成单审核通过'    //揭榜
        this.tableProp.table[13].tableOperate[6].status = '技术结题审核通过,市场结题审核不通过'    //市场结题
        this.tableProp.table[13].tableOperate[7].status = '验收审核通过,技术结题审核不通过'    //技术结题
        this.tableProp.table[13].tableOperate[8].status = '已指派审核通过'    //完工
        this.tableProp.table[13].tableOperate[9].status = '待成单审核不通过,待成单待审批'    //作废
        this.tableProp.table[13].tableOperate[10].status = 'control'    //作废
      }
      if(localStorage.getItem('departmentName') == '总经办'){
        this.tableProp.table[13].tableOperate[5].status = '0'    //揭榜
      }
      if(tab.name == 2 || tab.name == 3 || tab.name == 5 || tab.name == 6 || tab.name == 7 || tab.name == 8){
        this.type = 0
      } else{
        this.type = 1
      }
      this.initLoad()
    },
    //tabs 点击搜索
    keyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },
    //tabs 高级搜索点击确定
    highKeyWordMethod() {
      this.tableProp.page.current_page = 1;
      this.initLoad()
    },

    //table 修改 按钮点击
    editProject(row){
      this.$router.push({path: './new_project', query: {id: row.id}})
    },
    //table 详情 按钮点击
    detileProject(row) {
      this.$router.push({path:'./project_detail',query:{id:row.id}})
    },
    //table 不成单 按钮点击
    noPassOrder(row) {
      this.$confirm('确认不成单?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        noPass({id: row.id}).then((result)=>{
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      })
    },
    //table 删除 按钮点击
    discardProject(row) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        deletePro({ident_id: row.id, type : 'project_0'}).then((result)=>{
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      })

    },
    //table 分页 点击当前页
    pageCurrentChange(val) {
      this.tableProp.page.current_page = val
      this.initLoad();
    },
    handleSizeChange(val) {
      this.tableProp.page.size = val
      this.initLoad();
    },


    //申请报销相关
    reProject(row){
      this.dialogVisible = true
      this.bxId = row.id
    },
    jumpToCreateReimbursement(){
      //默认普通报销
      let path = "../../staff/my_center/reimbursement/normal"
      // let path = " ./reimbursement/normal"
      if(this.reimbursementType==2){
        //差旅费报销
        path = "../../staff/my_center/reimbursement/travel"
      }
      this.$router.push({path: path, query:{project_id: this.bxId}})
    },


    //成单相关
    //table 确认成单 按钮点击
    passOrder(row) {
      //模态框数据初始化
      this.cdData.addDialogShowData = this.$options.data().cdData.addDialogShowData
      this.cdshenP = this.$options.data().cdshenP
      this.cdData.addDialogShowData.id = row.id
      this.cdData.addDialogShowData.items_title = row.items_title
      this.cdData.addDialogShowData.project_name = row.project_title
      this.cdData.addDialogShowData.project_sn = row.project_sn
      this.cdData.addDialogShowData.security_level = row.security_level+''
      this.setText()
      this.cdData.addDialogShowData.deal_price = row.all_price
      this.cdData.addDialogShowData.start_time = row.start_time*1000
      this.cdData.addDialogShowData.end_time = row.end_time*1000
      this.cdData.addDialogShowData.cycle_time = row.cycle_time
      this.cdData.addDialogShowData.source_cost_is_rate = row.project_price.source_cost_is_rate+''
      this.cdData.addDialogShowData.source_cost = row.project_price.source_cost
      this.cdData.addDialogShowData.customer = row.customer
      this.cdData.addDialogShowData.customer_tel = row.customer_tel

      this.cdData.addDialogShowData.milepost = JSON.parse(JSON.stringify(row.li_cheng_bei))
      this.cdData.addDialogShowData.receive_money_plan = JSON.parse(JSON.stringify(row.shou_kuan_ji_hua))
      for (let i = 0; i < this.cdData.addDialogShowData.milepost.length; i++) {
        this.cdData.addDialogShowData.milepost[i].finish_time = this.cdData.addDialogShowData.milepost[i].finish_time*1000
      }
      for (let i = 0; i < this.cdData.addDialogShowData.receive_money_plan.length; i++) {
        this.cdData.addDialogShowData.receive_money_plan[i].expect_receive_time = this.cdData.addDialogShowData.receive_money_plan[i].expect_receive_time*1000
      }
      getProjectMember({project_id: row.id, type: 1}).then(res=>{
        this.member = res.data
      })
      if (row.audit_status == '审核不通过'){
        getCdinfo({project_id: row.id}).then((res)=>{
          this.cdshenP.data = JSON.parse(res.data.audit_json)
          for(let i = 0; i<res.data.project_files.length; i++){
            res.data.project_files[i].url = res.data.project_files[i].content
            res.data.project_files[i].power_member = JSON.parse(res.data.project_files[i].power_member)
          }
          this.cdData.addDialogShowData.deal_attachment_json = res.data.project_files

          this.cdData.addDialogShowData.security_level = res.data.security_level+''
          this.setText()
          this.cdData.addDialogShowData.deal_price = res.data.deal_price
          this.cdData.addDialogShowData.deal_sn = res.data.deal_sn
          this.cdData.addDialogShowData.start_time = res.data.start_time*1000
          this.cdData.addDialogShowData.end_time = res.data.end_time*1000
          this.cdData.addDialogShowData.cycle_time = res.data.cycle_time
          this.cdData.addDialogShowData.source_cost = res.data.source_cost
          this.cdData.addDialogShowData.customer = res.data.customer
          this.cdData.addDialogShowData.customer_tel = res.data.customer_tel

          this.cdData.addDialogShowData.milepost = JSON.parse(JSON.stringify(res.data.li_cheng_bei))
          this.cdData.addDialogShowData.receive_money_plan = JSON.parse(JSON.stringify(res.data.shou_kuan_ji_hua))
          for (let i = 0; i < this.cdData.addDialogShowData.milepost.length; i++) {
            this.cdData.addDialogShowData.milepost[i].finish_time = this.cdData.addDialogShowData.milepost[i].finish_time*1000
          }
          for (let i = 0; i < this.cdData.addDialogShowData.receive_money_plan.length; i++) {
            this.cdData.addDialogShowData.receive_money_plan[i].expect_receive_time = this.cdData.addDialogShowData.receive_money_plan[i].expect_receive_time*1000
          }
        })
      }
      this.cdData.isDialog = true
    },
    // 成单提交
    subCd() {
      let subData = JSON.parse(JSON.stringify(this.cdData.addDialogShowData))
      console.log(subData)
      //必填信息判断
      if (!subData.security_level || !subData.deal_price || !subData.start_time || !subData.end_time || !subData.cycle_time ||
          !subData.source_cost){
        this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      //收款计划判断
      if (subData.receive_money_plan.length==0){
        this.$confirm("收款计划不能为空", "提示", {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {}).catch(()=>{})
        return false
      }
      for (let i = 0; i < subData.receive_money_plan.length; i++) {
        if (!subData.receive_money_plan[i].expect_amount || !subData.receive_money_plan[i].condition || !subData.receive_money_plan[i].expect_receive_time){
          this.$confirm("收款计划填写不完整！", "提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {}).catch(()=>{})
          return false
        }
        if(subData.receive_money_plan[i].condition == 6 && subData.receive_money_plan[i].condition_other == ''){
          this.$confirm("付款条件为其他时，付款备注不能为空！", "提示", {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {}).catch(()=>{})
          return false
        }
        subData.receive_money_plan[i].expect_receive_time = subData.receive_money_plan[i].expect_receive_time/1000
      }
      //里程碑判断
      if (subData.milepost.length == 0){
        this.$confirm('里程碑至少填写一个！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      for (let i = 0; i < subData.milepost.length; i++) {
        if (!subData.milepost[i].title || !subData.milepost[i].finish_time || !subData.milepost[i].source_required){
          this.$confirm('里程碑信息填写不完整！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
        subData.milepost[i].finish_time = subData.milepost[i].finish_time/1000
      }

      //审批人判断
      if (this.cdshenP.data.length < 1){
        this.$confirm('至少选择一个审批人！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      } else {
        for (let i = 0; i < this.cdshenP.data.length; i++) {
          if (!this.cdshenP.data[i].user_id){
            this.$confirm('审批人信息填写不完整！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {})
            return false
          }
        }
      }

      subData.audit_json = JSON.stringify(this.cdshenP.data)
      subData.deal_attachment_json = JSON.stringify(subData.deal_attachment_json)
      subData.start_time = subData.start_time/1000
      subData.end_time = subData.end_time/1000
      subData.milepost = JSON.stringify(subData.milepost)
      subData.receive_money_plan = JSON.stringify(subData.receive_money_plan)


      cdSub(subData).then((result) => {
        this.cdData.isDialog = false;
        this.$message({
          message: result.msg,
          type: 'success'
        });
        this.initLoad()
      })
    },
    //设置保密文本
    setText(){
      switch (this.cdData.addDialogShowData.security_level) {
        case '1':
          this.cdData.levelText = '保密要求：一般保密，不得透露给公司以外人员任何项目信息；妥善保管相关文件资料，项目完成后全部归档处理。';
          break;
        case '2':
          this.cdData.levelText = '保密要求：重要保密，不得透露给公司以外人员；不得带出公司；同事之间不得相互交流；只能向指定项目人员发送；除项目需要不得将存有相关资料的U盘等存储设备带离公司；妥善保管相关文件资料，项目完成后全部归档处理。'
          break;
        case '3':
          this.cdData.levelText = '保密要求：重点保密，不得透露给项目以外人员；项目周期以外资料须销毁；不得带出公司；同事之间不得相互交流；只能向指定项目人员发送；未经允许不得拷贝到U盘，发送到微信、邮件等公共网络上。'
          break;
      }
    },
    //计算计划周期与交付时间
    getCycleTime(val) {
      if (this.cdData.addDialogShowData.end_time && this.cdData.addDialogShowData.start_time){
        this.cdData.addDialogShowData.cycle_time = timer(this.cdData.addDialogShowData.end_time - this.cdData.addDialogShowData.start_time)
        if (this.cdData.addDialogShowData.end_time < this.cdData.addDialogShowData.start_time){
          this.$confirm('结束时间必须大于开始时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            if (val == 'start_time'){
              this.cdData.addDialogShowData.start_time = ''
            }
            if (val == 'end_time'){
              this.cdData.addDialogShowData.end_time = ''
            }
          }).catch(() => {
            if (val == 'start_time'){
              this.cdData.addDialogShowData.start_time = ''
            }
            if (val == 'end_time'){
              this.cdData.addDialogShowData.end_time = ''
            }
          })
        }
      }
      if (val == 'start_time'){
        if (this.cdData.addDialogShowData.cycle_time && this.cdData.addDialogShowData.start_time){
          this.cdData.addDialogShowData.end_time = this.cdData.addDialogShowData.start_time + Number(this.cdData.addDialogShowData.cycle_time)*24*60*60*1000
        }
      }
    },
    setEndTime(){
      //判断输入是否为大于0的整数
      if(!Number(this.cdData.addDialogShowData.cycle_time) || Number(this.cdData.addDialogShowData.cycle_time) !== Number(Number(this.cdData.addDialogShowData.cycle_time).toFixed(0)) || Number(this.cdData.addDialogShowData.cycle_time) < 0){
        this.$confirm('计划项目周期必须为大于0的整数！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.cdData.addDialogShowData.cycle_time = ""
        })
        return false
      }
      if (this.cdData.addDialogShowData.start_time){
        this.cdData.addDialogShowData.end_time = this.cdData.addDialogShowData.start_time + Number(this.cdData.addDialogShowData.cycle_time)*24*60*60*1000
      }
    },
    //添加里程碑
    addMilepost(){
      let newMilepost = {title:'', finish_time:'', source_required:''}
      this.cdData.addDialogShowData.milepost.push(newMilepost)
    },
    //删除里程碑
    deleteMilepost(row, index){
      console.log(index)
      this.cdData.addDialogShowData.milepost.splice(index, 1)
    },
    //里程碑时间判断
    finish_timeChange(row){
      if (row.finish_time > this.cdData.addDialogShowData.end_time && row.finish_time){
        this.$confirm('结束时间不得晚于计划交付时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.finish_time = ''
        }).catch(() => {
          row.finish_time = ''
        })
      }
      if (row.finish_time < this.cdData.addDialogShowData.start_time && row.finish_time){
        this.$confirm('结束时间不得早于计划成单时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.finish_time = ''
        }).catch(() => {
          row.finish_time = ''
        })
      }
    },
    //添加收款期数
    addPlan(){
      let newPlan = {expect_amount:'', condition:'', condition_other:'', expect_receive_time:''}
      this.cdData.addDialogShowData.receive_money_plan.push(newPlan)
    },
    //删除收款期数
    deletePlan(row, index){
      this.cdData.addDialogShowData.receive_money_plan.splice(index, 1)
    },
    /*//添加功能模块
    // eslint-disable-next-line no-unused-vars
    renderContent(h, { node, data, store }) {
      return (
          <span class="custom-tree-node">
            <span>{node.label}</span>
            <span>
              <el-button size="mini" type="text" on-click={ () => this.openAdd(data) }>添加子节点</el-button>
              <el-button size="mini" type="text" on-click={ () => this.remove(node, data) }>删除</el-button>
            </span>
          </span>);
    },
    openAdd(data){
      this.newData = this.$options.data().newData
      this.gnData.type = 2
      this.gnData.clickData = data
      this.gnData.isDialog = true
    },
    addFirstGn(){
      this.newData = this.$options.data().newData
      this.gnData.type = 1
      this.gnData.isDialog = true

    },
    addMore(){
      this.newData.push({id: Math.round(Math.random()*123456+0), label: '', children: []})
    },
    deleteMore(index){
      this.newData.splice(index, 1)
    },
    append() {
      if (this.gnData.type == 1){
        this.cdData.addDialogShowData.function_json.push.apply(this.cdData.addDialogShowData.function_json, this.newData)
      } else {
        let data = this.gnData.clickData
        data.children.push.apply(data.children, this.newData);
      }
      this.gnData.name = ''
      this.gnData.isDialog = false
    },
    //移除功能模块
    remove(node, data) {
      const parent = node.parent;
      const children = parent.data.children || parent.data;
      const index = children.findIndex(d => d.id === data.id);
      children.splice(index, 1);
    },*/



    //获取部门
    getDepartment(){
      getDepartment().then((result) => {
        for (let i = 0; i < result.data.length; i++){
          result.data[i].value = result.data[i].id
          result.data[i].label = result.data[i].department_name
        }
        this.ryTableProp.department_nameProp = result.data
        this.ryTablePropjs.department_nameProp = result.data
        this.jhmxtableProp.department_nameProp = result.data
      })
    },
    //获取岗位
    getPost(val){
      val.post_name = ''
      val.user_id = ''
      let params = {pid : val.department_name}
      getPost(params).then((result) => {
        for (let i = 0; i < result.data.length; i++){
          result.data[i].value = result.data[i].id
          result.data[i].label = result.data[i].department_name
        }
        val.post_nameProp = result.data
      })
    },
    //获取人员
    getUser(val){
      val.user_id = ''
      let params = {department_id : val.post_name}
      getUser(params).then((result) => {
        for (let i = 0; i < result.data.length; i++){
          result.data[i].value = result.data[i].user_id
          result.data[i].label = result.data[i].name
        }
        val.user_idProp = result.data
      })
    },
    //获取人员姓名
    getUserName(val, key){

      let data = {
        user_id: val.user_id,
        user_name: ''
      }
      for (let i = 0; i<val.user_idProp.length; i++){
        if (val.user_idProp[i].user_id == val.user_id){
          data.user_name = val.user_idProp[i].name
        }
      }
      this.member.splice(key,1,data)
      // this.member.push(data)
    },



    //市场结题相关
    //市场结题点击
    endProject(row){
      this.getDepartment()
      this.jtData.data = this.$options.data().jtData.data
      this.jtshenP = this.$options.data().jtshenP
      this.jtData.data.project_id = row.id
      this.jtData.isDialog = true
      this.member = []
      this.jtData.data.member_json = []
      if (row.audit_status == '审核不通过'){
        getMc({project_id: row.id}).then((res)=>{
          this.jtData.data = res.data
          this.jtData.data.external_cost_is_rate = this.jtData.data.external_cost_is_rate + ''
          this.jtData.data.source_cost_is_rate = this.jtData.data.source_cost_is_rate + ''
          this.jtData.data.attachment_json = JSON.parse(res.data.attachment_json)
          if (res.data.member_json){
            this.jtData.data.member_json = JSON.parse(res.data.member_json)
            for (let i = 0; i< this.jtData.data.member_json.length; i++){
              this.jtData.data.member_json[i].red_rate = (this.jtData.data.member_json[i].red_rate*100).toFixed(1)
              this.jtData.data.member_json[i].grow_rate = (this.jtData.data.member_json[i].grow_rate*100).toFixed(1)
              let data = {
                user_id: this.jtData.data.member_json[i].user_id,
                user_name: ''
              }
              for (let a = 0; a<this.jtData.data.member_json[i].user_idProp.length; a++){
                if (this.jtData.data.member_json[i].user_idProp[a].user_id == this.jtData.data.member_json[i].user_id){
                  data.user_name = this.jtData.data.member_json[i].user_idProp[a].name
                }
              }
              this.member.splice(i,1,data)
            }
          }
          this.jtshenP.data = JSON.parse(res.data.audit_json)
        })
      }

    },
    //输入格式判断
    red_rateChange(row){
      let reg = /^(0|[1-9]\d*)(\.\d{1,1})?/
      let res = row.red_rate.match(reg)
      if (res[0] !==res.input ){
        this.$message({
          message: '最多保留一位小数！',
          type: 'error'
        });
        row.red_rate = res[0]
      }
    },
    grow_rateChange(row){
      let reg = /^(0|[1-9]\d*)(\.\d{1,1})?/
      let res = row.grow_rate.match(reg)
      if (res[0] !==res.input ){
        this.$message({
          message: '最多保留一位小数！',
          type: 'error'
        });
        row.grow_rate = res[0]
      }
    },
    // 添加市场成员 按钮点击
    addMember() {
      this.jtData.data.member_json.push({user_id:'', department_name: '', post_name: '', red_rate:'', grow_rate:'', post_nameProp: [], user_idProp: [], })
    },
    // 删除市场成员 按钮点击
    deleteMember(row, index) {
      this.jtData.data.member_json.splice(index, 1)
      this.member.splice(index, 1)
      for (let i = 0; i<this.jtData.data.attachment_json.length; i++){
        for (let a = 0; a<this.jtData.data.attachment_json[i].power_member.length; a++){
          let aaa = this.jtData.data.attachment_json[i].power_member[a]
          if(aaa == row.user_id){
            this.jtData.data.attachment_json[i].power_member.splice(a, 1)
          }
        }
      }
    },
    //市场结题提交
    subJt(){
      let subData = {...this.jtData.data}
      //必填项判断
      if (!subData.fund_summary || !subData.cycle_summary || !subData.customer_stability || !subData.second_ask || !subData.market_value ||
          !subData.real_market_cost || !subData.real_external_cost){
        this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      //审批人判断
      if (this.jtshenP.data.length < 1){
        this.$confirm('至少选择一个审批人！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      } else {
        for (let i = 0; i < this.jtshenP.data.length; i++) {
          if (!this.jtshenP.data[i].user_id){
            this.$confirm('审批人信息填写不完整！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {})
            return false
          }
        }
      }
      //参与成员验证
      let dataSum = JSON.parse(JSON.stringify(this.jtData.data.member_json))
      let red_rateSum = 0
      let grow_rateSum = 0
      for (let i = 0; i < dataSum.length; i++){
        red_rateSum = red_rateSum + parseFloat(dataSum[i].red_rate)
        grow_rateSum = grow_rateSum + parseFloat(dataSum[i].grow_rate)
        dataSum[i].red_rate = dataSum[i].red_rate/100
        dataSum[i].grow_rate = dataSum[i].grow_rate/100
        if (!dataSum[i].grow_rate || !dataSum[i].red_rate || !dataSum[i].user_id){
          this.$confirm('参与人员信息填写不完整！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
      }
      if (red_rateSum !== 100){
        this.$confirm('参与人员分红比例加和必须等于一百！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      if (grow_rateSum !== 100){
        this.$confirm('参与人员成长值比例加和必须等于一百！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      subData.member_json = dataSum
      subData.attachment_json = JSON.stringify(subData.attachment_json)
      subData.member_json = JSON.stringify(subData.member_json)
      subData.audit_json = JSON.stringify(this.jtshenP.data)
      jtSub(subData).then((result) => {
        this.jtData.isDialog = false;
        this.$message({
          message: result.msg,
          type: 'success'
        });
        this.initLoad()
      })
    },



    //技术结题相关
    //技术结题按钮点击
    endProjectJs(row) {
      this.getDepartment()
      this.jtDatajs.data = this.$options.data().jtDatajs.data
      this.shenPjs = this.$options.data().shenPjs
      this.jtDatajs.data.project_id = row.id
      getTaskDelayList({project_id: row.id}).then((result)=>{
        this.zqzjData = result.data
        this.jtDatajs.isDialog = true
      })
      this.member = []
      if(row.audit_status == '审核不通过'){
        getJtDetail({project_id: row.id}).then(res=>{
          this.jtDatajs.data.explain = res.data.explain
          this.jtDatajs.data.problem_summary = res.data.problem_summary
          this.jtDatajs.data.grow_summary = res.data.grow_summary
          this.jtDatajs.data.contribution_summary = res.data.contribution_summary
          this.jtDatajs.data.attachment_json = JSON.parse(res.data.attachment_json)
          this.jtData.data.member_json = []
          if (res.data.member_json){
            this.jtDatajs.data.member_json = JSON.parse(res.data.member_json)
            for (let i = 0; i< this.jtDatajs.data.member_json.length; i++){
              this.jtDatajs.data.member_json[i].red_rate = (this.jtDatajs.data.member_json[i].red_rate*100).toFixed(1)
              this.jtDatajs.data.member_json[i].grow_rate = (this.jtDatajs.data.member_json[i].grow_rate*100).toFixed(1)
              let data = {
                user_id: this.jtDatajs.data.member_json[i].user_id,
                user_name: ''
              }
              for (let a = 0; a<this.jtDatajs.data.member_json[i].user_idProp.length; a++){
                if (this.jtDatajs.data.member_json[i].user_idProp[a].user_id == this.jtDatajs.data.member_json[i].user_id){
                  data.user_name = this.jtDatajs.data.member_json[i].user_idProp[a].name
                }
              }
              this.member.splice(i,1,data)
            }
          }
          this.shenPjs.data = JSON.parse(res.data.audit_json)
        })
      }
    },
    // 添加市场成员 按钮点击
    addMemberjs() {
      this.jtDatajs.data.member_json.push({user_id:'', department_name: '', post_name: '', red_rate:'', grow_rate:'', post_nameProp: [], user_idProp: [],})
    },
    // 删除市场成员 按钮点击
    deleteMemberjs(row, index) {
      this.jtDatajs.data.member_json.splice(index, 1)
      this.member.splice(index, 1)
      for (let i = 0; i<this.jtDatajs.data.attachment_json.length; i++){
        for (let a = 0; a<this.jtDatajs.data.attachment_json[i].power_member.length; a++){
          let aaa = this.jtDatajs.data.attachment_json[i].power_member[a]
          if(aaa == row.user_id){
            this.jtDatajs.data.attachment_json[i].power_member.splice(a, 1)
          }
        }
      }
    },
    //技术结题提交
    subJtjs(){
      let subData = {...this.jtDatajs.data}
      //必填项判断
      if (!subData.explain || !subData.problem_summary || !subData.grow_summary || !subData.contribution_summary){
        this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      //审批人判断
      if (this.shenPjs.data.length < 1){
        this.$confirm('至少选择一个审批人！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      } else {
        for (let i = 0; i < this.shenPjs.data.length; i++) {
          if (!this.shenPjs.data[i].user_id){
            this.$confirm('审批人信息填写不完整！', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {})
            return false
          }
        }
      }
      //参与人员比例加和验证
      subData.member_json = JSON.parse(JSON.stringify(this.jtDatajs.data.member_json))
      let red_rateSum = 0
      let grow_rateSum = 0
      for (let i = 0; i< subData.member_json.length; i++){
        red_rateSum = red_rateSum + parseFloat(subData.member_json[i].red_rate)
        grow_rateSum = grow_rateSum + parseFloat(subData.member_json[i].grow_rate)
        subData.member_json[i].red_rate = subData.member_json[i].red_rate/100
        subData.member_json[i].grow_rate = subData.member_json[i].grow_rate/100
        if (!subData.member_json[i].grow_rate || !subData.member_json[i].red_rate || !subData.member_json[i].user_id){
          this.$confirm('参与人员信息填写不完整！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
      }
      if (red_rateSum !== 100){
        this.$confirm('参与人员分红比例加和必须等于一百！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      if (grow_rateSum !== 100){
        this.$confirm('参与人员成长值比例加和必须等于一百！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }

      console.log(subData)
      subData.attachment_json = JSON.stringify(subData.attachment_json)
      subData.member_json = JSON.stringify(subData.member_json)
      subData.audit_json = JSON.stringify(this.shenPjs.data)
      jtSubjs(subData).then((result) => {
        this.jtDatajs.isDialog = false;
        this.$message({
          message: result.msg,
          type: 'success'
        });
        this.initLoad()
      })
    },



    //完工相关
    //table 完工 按钮点击
    finish(row) {
      this.$confirm('确认完工?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        endProject({project_id: row.id, status: 4}).then(res=>{
          this.$message({
            message: res.msg,
            type: 'success'
          })
          this.initLoad()
        })
      })
    },


    //项目完结相关
    //项目完结按钮点击
    finishPro(row){
      this.$confirm('确认项目完结?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        projectFinish({project_id: row.id}).then(()=>{
          this.$message({
            message: '操作成功！',
            type: 'success'
          })
          this.initLoad()
        })
      })
    },


    //揭榜相关
    //table 揭榜 按钮点击
    getProject(row) {
      this.getDepartment();
      getshenPr().then((result)=>{
        this.userName = result.data
      })
      this.jbData.addDialogShowData = this.$options.data().jbData.addDialogShowData;
      this.jbData.addDialogShowData.user_id = parseInt(localStorage.getItem('userId'))
      this.jbData.addDialogShowData.project_id = row.id
      this.member = []
      jbDetail({project_id: row.id}).then(res=>{
        this.start_time = res.data.make_deal_audit_time*1000 - 86400000
        this.end_time = res.data.end_time * 1000
        this.jbtype = res.data.has_item
        if (res.data.has_item == 1){
          this.jbData.addDialogShowData.user_id = res.data.user_id
          this.jbData.addDialogShowData.dev_hope_red_rate = res.data.dev_hope_red_rate
          this.jbData.addDialogShowData.dev_hope_grow_rate = res.data.dev_hope_grow_rate
          this.jbData.addDialogShowData.deployment_req = res.data.deployment_req
          this.jbData.addDialogShowData.mission_req = res.data.mission_req
          this.jbData.addDialogShowData.cycle_rp = res.data.cycle_rp
          this.jbData.addDialogShowData.task_completion_rp = res.data.task_completion_rp
          this.jbData.addDialogShowData.other_reward = res.data.other_reward
          this.jbData.addDialogShowData.id = res.data.id
          this.jbData.addDialogShowData.attachment_json = JSON.parse(res.data.attachment_json)
          if (res.data.member_json){
            this.jbData.addDialogShowData.member_json = JSON.parse(res.data.member_json)
            for (let i = 0; i< this.jbData.addDialogShowData.member_json.length; i++){
              this.jbData.addDialogShowData.member_json[i].start_time = this.jbData.addDialogShowData.member_json[i].start_time*1000
              this.jbData.addDialogShowData.member_json[i].end_time = this.jbData.addDialogShowData.member_json[i].end_time*1000
              let data = {
                user_id: this.jbData.addDialogShowData.member_json[i].user_id,
                user_name: ''
              }
              for (let a = 0; a<this.jbData.addDialogShowData.member_json[i].user_idProp.length; a++){
                if (this.jbData.addDialogShowData.member_json[i].user_idProp[a].user_id == this.jbData.addDialogShowData.member_json[i].user_id){
                  data.user_name = this.jbData.addDialogShowData.member_json[i].user_idProp[a].name
                }
              }
              this.member.splice(i,1,data)
            }
          }
        }
      })

      this.jbData.title = '揭榜';
      this.jbData.isDialog = true;

    },
    //格式判断
    start_timeChange(row){
      if (row.start_time < this.start_time && row.start_time){
        this.$confirm('开始时间不得早于成单审批通过时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.start_time = ''
        })
            .catch(() => {
              row.start_time = ''
            })
      }
      if (row.start_time > this.end_time && row.start_time){
        this.$confirm('开始时间不得晚于项目计划交付时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.start_time = ''
        })
            .catch(() => {
              row.start_time = ''
            })
      }
      if (row.end_time && row.start_time){
        if (row.end_time < row.start_time){
          this.$confirm('开始时间不得晚于结束时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            row.start_time = ''
          })
              .catch(() => {
                row.start_time = ''
              })
        }
      }
    },
    end_timeChange(row){
      if (row.end_time < this.start_time && row.end_time){
        this.$confirm('结束时间不得早于成单审批通过时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.end_time = ''
        })
            .catch(() => {
              row.end_time = ''
            })
      }
      if (row.end_time > this.end_time && row.end_time){
        this.$confirm('结束时间不得晚于项目计划交付时间！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          row.end_time = ''
        })
            .catch(() => {
              row.end_time = ''
            })
      }
      if (row.start_time && row.end_time){
        if (row.end_time < row.start_time){
          this.$confirm('结束时间不得早于开始时间！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            row.end_time = ''
          })
              .catch(() => {
                row.end_time = ''
              })
        }
      }
    },
    join_rateChange(row){
      let reg = /^(0|[1-9]\d*)(\.\d{1,1})?/
      let res = row.join_rate.match(reg)
      if (res[0] !==res.input ){
        this.$message({
          message: '最多保留一位小数！',
          type: 'error'
        });
        row.join_rate = res[0]
      }
    },
    // 揭榜提交
    jbSub() {
      let subData = JSON.parse(JSON.stringify(this.jbData.addDialogShowData))
      //必填项判断
      if (!subData.dev_hope_red_rate || !subData.dev_hope_grow_rate || !subData.deployment_req || !subData.mission_req || !subData.cycle_rp ||
          !subData.task_completion_rp || !subData.other_reward){
        this.$confirm('*为必填项信息，请填写完整后提交！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }
      //计划参与度计算验证
      let sumJoin_rate = 0
      for (let i = 0; i< subData.member_json.length; i++){
        subData.member_json[i].start_time = subData.member_json[i].start_time/1000
        subData.member_json[i].end_time = subData.member_json[i].end_time/1000
        sumJoin_rate = sumJoin_rate + parseFloat(subData.member_json[i].join_rate)
        if (!subData.member_json[i].user_id || !subData.member_json[i].join_rate){
          this.$confirm('计划明细表信息填写不完整！', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {})
          return false
        }
      }
      if (sumJoin_rate !== 100){
        this.$confirm('参与成员计划参与度加和必须等于一百！', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {})
        return false
      }

      subData.member_json = JSON.stringify(subData.member_json);
      subData.attachment_json = JSON.stringify(subData.attachment_json);
      if(this.jbtype == 0){
        projectItem(subData).then((result) => {
          this.jbData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      } else {
        editProjectItem(subData).then((result) => {
          this.jbData.isDialog = false;
          this.$message({
            message: result.msg,
            type: 'success'
          });
          this.initLoad()
        })
      }
    },
    //删除成员
    deleteMemberjb(row, index) {
      this.jbData.addDialogShowData.member_json.splice(index, 1)
      this.member.splice(index, 1)
      for (let i = 0; i<this.jbData.addDialogShowData.attachment_json.length; i++){
        for (let a = 0; a<this.jbData.addDialogShowData.attachment_json[i].power_member.length; a++){
          let aaa = this.jbData.addDialogShowData.attachment_json[i].power_member[a]
          if(aaa == row.user_id){
            this.jbData.addDialogShowData.attachment_json[i].power_member.splice(a, 1)
          }
        }
      }
    },
    // 添加成员 按钮点击
    addMemberjb() {
      let item = {
        user_id: '',
        department_name: '',
        post_name: '',
        task_name:'',
        start_time: "",
        end_time: "",
        join_rate: '',
        post_nameProp: [],
        user_idProp: [],
      }
      this.jbData.addDialogShowData.member_json.push(item)
    },

  //  表格懒加载展开行
    load(tree, treeNode, resolve) {
      getProChildren({project_id: tree.id}).then((res)=>{
          console.log(res)
        for (let i = 0; i < res.data.length; i++){
          //状态:-1=作废，0=待成单，1=成单，2=不成单，3=已指派（进行中，待完工），4=完工（待验收），5=验收（已完成）  ，6=技术结题，7=市场结题，8=项目完结
          switch (res.data[i].status) {
            case 0:
              res.data[i].status = '待成单';
              break;
            case -1:
              res.data[i].status = '作废';
              break;
            case 1:
              res.data[i].status = '成单';
              break;
            case 2:
              res.data[i].status = '不成单';
              break;
            case 3:
              res.data[i].status = '已指派';
              break;
            case 4:
              res.data[i].status = '完工';
              break;
            case 5:
              res.data[i].status = '验收';
              break;
            case 6:
              res.data[i].status = '技术结题';
              break;
            case 7:
              res.data[i].status = '市场结题';
              break;
            case 8:
              res.data[i].status = '项目完结';
              break;
          }
          //审批状态：0=待审批，1=审核通过，2=审核不通过
          switch (res.data[i].audit_status) {
            case 0:
              res.data[i].audit_status = '待审批';
              break;
            case 1:
              res.data[i].audit_status = '审核通过';
              break;
            case 2:
              res.data[i].audit_status = '审核不通过';
              break;
          }
          switch (res.data[i].can_re) {
            case 20000:
              res.data[i].reProjectControl = 'control';
              break;
            case 0:
              res.data[i].reProjectControl = '0';
              break;
          }
          switch (res.data[i].edit_btn) {
            case 1:
              res.data[i].editProjectControl = 'control';
              break;
            case 0:
              res.data[i].editProjectControl = '0';
              break;
          }
          res.data[i].statusName = res.data[i].status + res.data[i].audit_status

          if (res.data[i].audit_status_info){
            for (let a = 0; a < res.data[i].audit_status_info.length; a++){
              switch (res.data[i].audit_status_info[a].status) {
                case 0:
                  res.data[i].audit_status_info[a].status = '待审批';
                  break;
                case 1:
                  res.data[i].audit_status_info[a].status = '审核通过';
                  break;
                case 2:
                  res.data[i].audit_status_info[a].status = '审核不通过';
                  break;
                case 3:
                  res.data[i].audit_status_info[a].status = '重新审批';
                  break;
              }
              switch (res.data[i].audit_status_info[a].check) {
                case 0:
                  res.data[i].audit_status_info[a].status = '查看';
                  break;
              }
            }
          } else {
            res.data[i].audit_status_info = [{name: '无'}]
          }

          if (res.data[i].children_project_count !== 0){
            res.data[i].hasChildren = true
          }
        }
        resolve(res.data)
      })
    }

  },
};
</script>

<style>
.custom-tree-container{
  width: 300px;
}
.custom-tree-node{
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 14px;
  padding-right: 8px;
}
</style>

<style lang="scss" scoped>
.cdul{
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }


  }
}
.common-add-dialog {
  li:nth-last-of-type(1){
    margin-bottom: 0;
    align-items: baseline;
  }
  li {
    margin-bottom: 15px;

    span {
      width: 120px;
      text-align: right;
      margin-right: 15px;
      flex: 0 0 auto;
    }

    @include flex();
    //下拉框
    .el-select {
      flex: 1;
    }
    //单选
    .el-radio{
      margin: 3px 30px 3px 0;
    }
    //多选
    .el-checkbox{
      margin: 3px 30px 3px 0;
    }

  }
}


</style>
