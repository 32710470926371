import http from '../http';

export function projectList (params) {
    return http({
        url: "/api/project/projectPage",
        method: "get",
        params:params
    })
}
export function projectDetial (params) {
    return http({
        url: "/api/project/project",
        method: "get",
        params:params
    })
}
export function jtSubjs (data) {
    return http({
        url: "/api/project/projectDc",
        method: "put",
        data:data
    })
}
export function getTaskDelayList (params) {
    return http({
        url: "/api/project/taskDelayList",
        method: "get",
        params:params
    })
}
//完工
export function endProject (data) {
    return http({
        url: "/api/project/projectComplete",
        method: "post",
        data:data
    })
}

//项目完结
export function projectFinish (data) {
    return http({
        url: "/api/project/projectFinish",
        method: "post",
        data:data
    })
}

//结题详情
export function getJtDetail (params) {
    return http({
        url: "/api/project/projectDc",
        method: "get",
        params:params
    })
}
//获取商务负责人
export function getbusUser (params) {
    return http({
        url: "/api/user/departmentUserList",
        method: "get",
        params:params
    })
}


